'use strict'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Popover } from 'antd'
import { useDispatch } from 'react-redux'
import { auth } from '../../core/actions/registrationAction'
import style from './Header.module.scss'

export default function Header() {

  const [open, setOpen] = useState(false)
  const [moveArrow, setMoveArrow] = useState(false)
  const [riseProjects, setRiseProjects] = useState(false)
  const [cabinet, setСabinet] = useState(false)
  const [createSite, setCreateSite] = useState(false)
  const [circle, setCircle] = useState(false)

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const hidePopap = (arg) => {
    setTimeout(() => {
      navigate('/authorization')
    }, 600)
    setOpen(false)
    dispatch(auth(arg))
  }
  const onMoveTopRight = () => {
    setMoveArrow(true)
    setTimeout(() => {
      setMoveArrow(false)
      navigate('/contact')
    }, 800)
  }
  const onMoveBottomRight = () => {
    setCreateSite(true)
    setTimeout(() => {
      setCreateSite(false)
      navigate('/')
    }, 800)
    
  }
  const onMoveTopLeft = () => {
    setRiseProjects(true)
    setTimeout(() => {
      setRiseProjects(false)
      navigate('/projects')
    }, 800)
  }
  const onMoveBottomLeft = () => {
    setСabinet(true)
    setOpen(value => !value)
    setTimeout(() => {
      setСabinet(false)
    }, 800)
  }
  useEffect(() => {
    setCircle(true)
    setTimeout(() => {
      setCircle(false)
    }, 800)
  }, [])
  const onMoveCircle = () => {
    setCircle(true)
    setTimeout(() => {
      navigate("/home")
    }, 500)
    
    setTimeout(() => {
      setCircle(false)
    }, 800)
  }

  const registration = <div className={style.registration}>
    <span className={style.textAuth} onClick={() => hidePopap('registration')}>Registration</span>   
    <span className={style.textAuth} onClick={() => hidePopap('login')}>LogIn</span>
  </div>

  
  return (
    <>
      <div className={style.wrapper}>
        <div className={style.contentLeft}>
          <div className={style.topLeft} 
            onClick={() => onMoveTopLeft()}
          >
            <div className={riseProjects ? style.moveProjects : style.projects}>[ PROJECTS ]</div>
          </div>
          
          <div className={style.bottomLeft}
            onClick={() => onMoveBottomLeft()}
          >
            <div className={cabinet ? style.moveСabinet : style.cabinet}>
              <div className={style.textСabinet}/>
            </div>
          </div>
          <Popover
            placement="bottomRight"
            content={registration}
            trigger="click"
            open={open}
            style={{height: 30}}
          >
          </Popover>
        </div>

        <div
          className={style.content}
          onClick={() => onMoveCircle()}
        >
          <div className={circle ? style.moveCircle : style.circle}>
            <span className={style.textCircle}>HOME<br/>PAGE</span>
          </div>
        </div>
    
        <div className={style.contentRight}>
          <div className={style.topRight} 
            onClick={() => onMoveTopRight()}
          >
            <div className={moveArrow ? style.moveArrow : style.arrow}></div>
          </div>
          <div className={style.bottomRight}
            onClick={() => onMoveBottomRight()}
          >
            <div className={createSite ? style.moveLeftServises : style.leftServises}></div>
              <div className={style.createSite}>
                <div className={createSite ? style.moveTextCreateSite : style.textCreateSite}/>
              </div>
            <div className={createSite ? style.moveRightServises : style.rightServices}></div>
          </div>
        </div>
      </div>
    </> 
  )
}

